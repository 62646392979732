import React, { useState, useEffect } from "react";
import axios from "axios";
import "./New.css";

function Bankpartners() {
  const [loanBanks, setLoanBanks] = useState([]);
  const [creditCardBanks, setCreditCardBanks] = useState([]);
  const [insuranceBanks, setInsuranceBanks] = useState([]);
  const [loanNBFCs, setLoanNBFCs] = useState([]);
  const [creditCardNBFCs, setCreditCardNBFCs] = useState([]);
  const [insuranceNBFCs, setInsuranceNBFCs] = useState([]);

  const getBankData = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyPartnerBanks"
    );
    const bankData = response.data;
    setLoanBanks(bankData.filter((item) => item.category === "loan"));
    setCreditCardBanks(
      bankData.filter((item) => item.category === "credit_card")
    );
    setInsuranceBanks(bankData.filter((item) => item.category === "insurance"));
  };

  const getNBFCData = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyPartnerNonBanks"
    );
    const nbfcData = response.data;
    setLoanNBFCs(nbfcData.filter((item) => item.category === "loan"));
    setCreditCardNBFCs(
      nbfcData.filter((item) => item.category === "credit_card")
    );
    setInsuranceNBFCs(nbfcData.filter((item) => item.category === "insurance"));
  };

  useEffect(() => {
    getBankData();
    getNBFCData();
  }, []);

  return (
    <section className="partners-section pt-120 pb-120">
      <div className="container" style={{ marginBottom: "50px" }}>
        <ul
          className="nav nav-pills justify-content-center mb-4"
          id="partner-tabs"
          role="tablist"
        >
          <li className="nav-item">
            <button
              className="nav-link active togglebutton"
              id="bank-tab"
              data-bs-toggle="pill"
              data-bs-target="#banks"
              type="button"
              role="tab"
              aria-controls="banks"
              aria-selected="true"
            >
              Bank Partners
            </button>
          </li>
          <li className="nav-item">
            <button
              className="nav-link togglebutton"
              id="nbfc-tab"
              data-bs-toggle="pill"
              data-bs-target="#nbfcs"
              type="button"
              role="tab"
              aria-controls="nbfcs"
              aria-selected="false"
            >
              NBFC Partners
            </button>
          </li>
        </ul>

        <div className="tab-content" id="partner-tabs-content">
          {/* Bank Partners Tab */}
          <div
            className="tab-pane fade show active"
            id="banks"
            role="tabpanel"
            aria-labelledby="bank-tab"
          >
            <div className="table-responsive">
              <table className="table table-bordered text-center">
                <thead style={{ backgroundColor: "red" }}>
                  <tr>
                    <th style={{ color: "white", textAlign: "center" }}></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="loan-bank-scroll-wrapper">
                        <div className="loan-bank-scroll-container">
                          {loanBanks.map((bank, index) => (
                            <div
                              key={index}
                              className="loan-bank-scroll-item d-flex justify-content-center mb-3 p-1"
                              style={{ padding: "0" }}
                            >
                              <img
                                src={`https://api.dremerz.net/uploads/${bank.image}`}
                                alt={bank.name}
                                className="img-fluid"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </div>
                          ))}
                          {/* Duplicate images for seamless scrolling */}
                          {loanBanks.map((bank, index) => (
                            <div
                              key={index + loanBanks.length} // Ensure unique key
                              className="loan-bank-scroll-item d-flex justify-content-center mb-3 p-1"
                              style={{ padding: "0" }}
                            >
                              <img
                                src={`https://api.dremerz.net/uploads/${bank.image}`}
                                alt={bank.name}
                                className="img-fluid"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* NBFC Partners Tab */}
          <div
            className="tab-pane fade"
            id="nbfcs"
            role="tabpanel"
            aria-labelledby="nbfc-tab"
          >
            <div className="table-responsive">
              <table className="table table-bordered text-center">
                <thead style={{ backgroundColor: "red" }}>
                  <tr>
                    <th style={{ color: "white", textAlign: "center" }}></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="loan-bank-scroll-wrapper">
                        <div className="loan-bank-scroll-container">
                          {loanNBFCs.map((bank, index) => (
                            <div
                              key={index}
                              className="loan-bank-scroll-item d-flex justify-content-center mb-3 p-1"
                              style={{ padding: "0" }}
                            >
                              <img
                                src={`https://api.dremerz.net/uploads/${bank.image}`}
                                alt={bank.name}
                                className="img-fluid"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </div>
                          ))}
                          {/* Duplicate images for seamless scrolling */}
                          {loanNBFCs.map((bank, index) => (
                            <div
                              key={index + loanBanks.length} // Ensure unique key
                              className="loan-bank-scroll-item d-flex justify-content-center mb-3 p-1"
                              style={{ padding: "0" }}
                            >
                              <img
                                src={`https://api.dremerz.net/uploads/${bank.image}`}
                                alt={bank.name}
                                className="img-fluid"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Bankpartners;
