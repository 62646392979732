import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const Addcomparision = () => {
  const [formData, setFormData] = useState({
    bankName: "",
    loanAmount: "",
    interestRate: "",
    loansApproved: "",
    maxTenure: "",
  });

  const [logo, setLogo] = useState(null); // State to handle the logo file
  const [banks, setBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchBankData();
  }, []);

  const fetchBankData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://api.dremerz.net/api/mybankshoppyaddbankcomparisontable`
      );
      setBanks(response.data);
    } catch (error) {
      console.error("Error fetching bank data:", error);
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageUpload = (e) => {
    setLogo(e.target.files[0]); // Store the selected file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("logo", logo); // Append the logo file
    formDataToSend.append("bankName", formData.bankName);
    formDataToSend.append("loanAmount", formData.loanAmount);
    formDataToSend.append("interestRate", formData.interestRate);
    formDataToSend.append("loansApproved", formData.loansApproved);
    formDataToSend.append("maxTenure", formData.maxTenure);

    try {
      await axios.post(
        `https://api.dremerz.net/api/mybankshoppyaddbankcomparisontable`,
        formDataToSend
      );
      fetchBankData();
      setFormData({
        bankName: "",
        loanAmount: "",
        interestRate: "",
        loansApproved: "",
        maxTenure: "",
      });
      setLogo(null); // Reset the logo state
    } catch (error) {
      console.error("Error saving bank data:", error);
    }
  };

  // Handle delete operation
  const handleDelete = async (id) => {
    const isConfirm = window.confirm("Are You Sure Want To Remove This Bank");
    if (!isConfirm) {
      return;
    } else {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/mybankshoppyaddbankcomparisontable/${id}`
        ); // DELETE request
        fetchBankData(); // Fetch updated data after deletion
      } catch (error) {
        console.error("Error deleting bank data:", error);
      }
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Add Bank Data</h2>

      {/* Form to add bank data */}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="bankName">Bank Name</label>
            <input
              type="text"
              className="form-control"
              id="bankName"
              name="bankName"
              value={formData.bankName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="logo">Upload Logo</label>
            <input
              type="file"
              className="form-control"
              id="logo"
              name="logo"
              accept="image/*" // Optional: Restrict file types to images
              onChange={handleImageUpload}
              required
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="loanAmount">Loan Amount</label>
            <input
              type="text"
              className="form-control"
              id="loanAmount"
              name="loanAmount"
              value={formData.loanAmount}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="interestRate">Interest Rate</label>
            <input
              type="text"
              className="form-control"
              id="interestRate"
              name="interestRate"
              value={formData.interestRate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="loansApproved">Loans Approved</label>
            <input
              type="text"
              className="form-control"
              id="loansApproved"
              name="loansApproved"
              value={formData.loansApproved}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="maxTenure">Max Tenure</label>
            <input
              type="text"
              className="form-control"
              id="maxTenure"
              name="maxTenure"
              value={formData.maxTenure}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <br></br>
        <button type="submit" className="btn btn-primary">
          Save Bank Data
        </button>
      </form>

      {/* Display bank data */}
      <h2 className="mt-4 mb-4">Bank Data</h2>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Logo</th>
              <th>Bank Name</th>
              <th>Loan Amount</th>
              <th>Interest Rate</th>
              <th>Loans Approved</th>
              <th>Max Tenure</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {banks.map((bank) => (
              <tr key={bank.id}>
                <td>
                  <img
                    src={`https://api.dremerz.net/uploads/${bank.logo}`}
                    alt={`${bank.bankName} logo`}
                    className="bank-logo"
                    style={{ width: "50px", height: "auto" }} // Optional: Set dimensions for the logo
                  />
                </td>
                <td>{bank.bankName}</td>
                <td>{bank.loanAmount}</td>
                <td>{bank.interestRate}</td>
                <td>{bank.loansApproved}</td>
                <td>{bank.maxTenure}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(bank.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Addcomparision;
