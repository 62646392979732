import React, { useState } from "react";
import Slider from "react-slider";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Table,
  Nav,
  Button,
} from "react-bootstrap";
import "./Emicalculator.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const EMICalculator = () => {
  const [loanType, setLoanType] = useState("home");
  const [loanAmount, setLoanAmount] = useState(1000000);
  const [interestRate, setInterestRate] = useState(9);
  const [loanTenure, setLoanTenure] = useState(10);
  const [showMoreRows, setShowMoreRows] = useState(false);
  const initialRowCount = 10;

  const toggleRows = () => setShowMoreRows(!showMoreRows);

  const handleTabChange = (type) => {
    setLoanType(type);
    switch (type) {
      case "personal":
        setInterestRate(11);

        break;
      case "car":
        setInterestRate(8.5);
        break;
      case "home":
      default:
        setInterestRate(9);
        break;
    }
  };

  const calculateEMI = () => {
    const monthlyInterestRate = interestRate / 12 / 100;
    const tenureInMonths = loanTenure * 12;

    if (loanAmount <= 0 || interestRate < 0 || loanTenure <= 0) return 0;

    const emi =
      (loanAmount *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, tenureInMonths)) /
      (Math.pow(1 + monthlyInterestRate, tenureInMonths) - 1);
    return Math.round(emi);
  };

  const totalInterest = calculateEMI() * loanTenure * 12 - loanAmount;
  const totalPayment = loanAmount + totalInterest;

  const data = {
    labels: ["Principal Loan Amount", "Total Interest"],
    datasets: [
      {
        data: [loanAmount, totalInterest],
        backgroundColor: ["#4caf50", "#ff9800"],
        hoverBackgroundColor: ["#66bb6a", "#ffa726"],
      },
    ],
  };

  const generateAmortizationTable = () => {
    const emi = calculateEMI();
    const monthlyInterestRate = interestRate / 12 / 100;
    let balance = loanAmount;
    const rows = [];

    for (let month = 1; month <= loanTenure * 12; month++) {
      const interestPayment = balance * monthlyInterestRate;
      let principalPayment = emi - interestPayment;

      if (balance < principalPayment) {
        principalPayment = balance;
      }

      balance -= principalPayment;

      const remainingBalance = Math.max(balance, 0);

      rows.push({
        month,
        principalPayment: Math.round(principalPayment),
        interestPayment: Math.round(interestPayment),
        totalPayment: Math.round(principalPayment + interestPayment),
        balance: Math.round(remainingBalance),
      });

      if (remainingBalance === 0) {
        break;
      }
    }

    return rows;
  };

  const amortizationTableData = generateAmortizationTable();

  return (
    <Container className="emi-calculator-container p-4">
      <Row>
        <Col md={12} className="mb-3" style={{ textAlign: "center" }}>
          <h4 className="emi-title">
            EMI Calculator for Home Loan, Car Loan & Personal Loan in India
          </h4>
        </Col>
      </Row>
      <Nav variant="tabs" className="emi-calculator-tabs mb-4">
        <Nav.Item>
          <Nav.Link
            active={loanType === "home"}
            onClick={() => handleTabChange("home")}
            style={{ color: "black" }}
          >
            Home Loan
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            active={loanType === "personal"}
            onClick={() => handleTabChange("personal")}
            style={{ color: "black" }}
          >
            Personal Loan
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            active={loanType === "car"}
            onClick={() => handleTabChange("car")}
            style={{ color: "black" }}
          >
            Car Loan
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Row>
        <Col md={12}>
          <Form.Group>
            <Form.Label>Loan Amount</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                value={loanAmount}
                onChange={(e) => setLoanAmount(Number(e.target.value))}
                className="emi-input"
              />
              <InputGroup.Text>₹</InputGroup.Text>
            </InputGroup>
            <Slider
              className="emi-calculator-slider"
              max={20000000}
              step={100000}
              value={loanAmount}
              onChange={(value) => setLoanAmount(value)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Group>
            <Form.Label>Interest Rate (%)</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                value={interestRate}
                onChange={(e) => setInterestRate(Number(e.target.value))}
                className="emi-input"
              />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
            <Slider
              className="emi-calculator-slider"
              max={20}
              step={0.1}
              value={interestRate}
              onChange={(value) => setInterestRate(value)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Group>
            <Form.Label>Loan Tenure (in years)</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                value={loanTenure}
                onChange={(e) => setLoanTenure(Number(e.target.value))}
                className="emi-input"
              />
              <InputGroup.Text>Years</InputGroup.Text>
            </InputGroup>

            <Slider
              className="emi-calculator-slider"
              max={30}
              step={1}
              value={loanTenure}
              onChange={(value) => setLoanTenure(value)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6} style={{ marginTop: "50px" }}>
          <h5 className="emi-details-title">Loan Details</h5>
          <Table striped bordered hover className="emi-table">
            <tbody>
              <tr>
                <td>Loan EMI</td>
                <td>₹ {calculateEMI().toLocaleString("en-IN")}</td>
              </tr>
              <tr>
                <td>Total Interest Payable</td>
                <td>₹ {totalInterest.toLocaleString("en-IN")}</td>
              </tr>
              <tr>
                <td>Total Payment (Principal + Interest)</td>
                <td>₹ {totalPayment.toLocaleString("en-IN")}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col
          md={6}
          className="piechart"
          style={{
            height: "300px",
            width: "300px",
            marginLeft: "50px",
          }}
        >
          <h5 className="emi-details-title">Break-up of Total Payment</h5>
          <Pie data={data} />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <h5 className="emi-details-title">Amortization Schedule</h5>
          <div className="table-responsive">
            <Table striped bordered hover className="emi-table">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Principal Payment</th>
                  <th>Interest Payment</th>
                  <th>Total Payment</th>
                  <th>Remaining Balance</th>
                </tr>
              </thead>
              <tbody>
                {/* Display the first 10 rows */}
                {amortizationTableData.slice(0, initialRowCount).map((row) => (
                  <tr key={row.month}>
                    <td>{row.month}</td>
                    <td>₹ {row.principalPayment.toLocaleString("en-IN")}</td>
                    <td>₹ {row.interestPayment.toLocaleString("en-IN")}</td>
                    <td>₹ {row.totalPayment.toLocaleString("en-IN")}</td>
                    <td>₹ {row.balance.toLocaleString("en-IN")}</td>
                  </tr>
                ))}
                {/* Conditionally show remaining rows */}
                {showMoreRows &&
                  amortizationTableData.slice(initialRowCount).map((row) => (
                    <tr key={row.month}>
                      <td>{row.month}</td>
                      <td>₹ {row.principalPayment.toLocaleString("en-IN")}</td>
                      <td>₹ {row.interestPayment.toLocaleString("en-IN")}</td>
                      <td>₹ {row.totalPayment.toLocaleString("en-IN")}</td>
                      <td>₹ {row.balance.toLocaleString("en-IN")}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          {/* Toggle button to show more/less rows */}
          <div className="text-center">
            <Button variant="link" onClick={toggleRows}>
              {showMoreRows ? (
                <button className="showbutton">&#9650; Show Less</button> // Up arrow
              ) : (
                <button className="showbutton">&#9660; Show More</button> // Down arrow
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EMICalculator;
