import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Bankcomparision.css";

const Bankcomparision = () => {
  const [banks, setBanks] = useState([]);

  const getBanks = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyaddbankcomparisontable"
      );
      console.log(response.data, "response");
      setBanks(response.data);
    } catch (error) {
      console.error("Error fetching bank data:", error);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  const cardStyle = {
    border: "1px solid #e2e2e2",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
    padding: "15px",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    cursor: "pointer",
  };

  const logoStyle = {
    height: "50px",
    objectFit: "cover",
    marginBottom: "10px",
  };

  const buttonStyle = {
    backgroundColor: "#E63928",
    color: "white",
    border: "none",
    padding: "10px 15px",
    borderRadius: "5px",
    cursor: "pointer",
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = "scale(1.05)";
    e.currentTarget.style.boxShadow = "0 4px 15px rgba(0, 0, 0, 0.2)";
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = "scale(1)";
    e.currentTarget.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.1)";
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4 text-center">
        <strong>Top Banks</strong>
      </h2>
      <div className="row">
        {banks.map((bank, index) => (
          <div key={index} className="col-md-12 mb-3">
            <div
              className="row"
              style={cardStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="col-md-4 d-flex flex-column align-items-start">
                <img
                  src={`https://api.dremerz.net/uploads/${bank.logo}`}
                  alt={`${bank.bankName} logo`}
                  style={logoStyle}
                />
                <strong className="bank-name">{bank.bankName}</strong>
                <p className="loans-approved">
                  <i
                    className="fas fa-check-circle"
                    style={{ color: "#E63928", marginRight: "5px" }}
                  ></i>
                  {new Intl.NumberFormat("en-IN").format(bank.loansApproved)}{" "}
                  <br></br>Loans Approved
                </p>
              </div>

              <div className="col-md-4" style={{ textAlign: "center" }}>
                <p>
                  <strong>Loan Amount:</strong>
                  <br /> {bank.loanAmount}
                </p>
                <p>
                  <strong>Max Tenure:</strong>
                  <br /> {bank.maxTenure}
                </p>
              </div>

              <div className="col-md-4 text-center">
                <p>
                  <strong>Int. Rate:</strong>
                  <br /> {bank.interestRate}
                </p>
                <a href="/applynow">
                  <button style={buttonStyle}>Apply Now</button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bankcomparision;
