import { Link } from "react-router-dom";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faInstagram,
  faYoutube,
  faLinkedin,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "./Main.css";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="row row-gutter-y-30 main-foot">
          <div className="col-lg-4 col-md-6">
            <div className="footer-widget footer-widget--about">
              {/* <Link to="/" className="footer-widget__logo">
                  <img
                    src="assets/images/mybankshoppy.png"
                    alt="mybankshoppy"
                    width="auto"
                    height="300"
                  />
                </Link> */}
              <div className="footer-widget footer-widget--links">
                <h3 className="footer-widget__title">Reach out us</h3>
              </div>

              <p className="footer-widget__text"></p>
              <ul className="list-unstyled footer-widget__info">
                <li>
                  <i className="icon-email"></i>
                  <a href="mailto:sales@mybankshoppy.com">
                    sales@mybankshoppy.com
                  </a>
                </li>
                <li>
                  <i className="icon-telephone"></i>
                  <a href="tel:9884063673">+91 9884063673</a>
                </li>
              </ul>
              <div className="footer-widget__social">
                <a
                  href="https://x.com/mybankshoppy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faXTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/mybankshoppyinsta/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://www.youtube.com/@MyBankShoppy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a
                  href="https://www.facebook.com/mybankshoppy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="footer-widget footer-widget--links">
              <h3 className="footer-widget__title">Explore</h3>
              <ul className="list-unstyled footer-widget__menu">
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/service">Our Services</Link>
                </li>

                {/* <li>
                    <Link to="/applynow">Apply for Loan</Link>
                  </li> */}
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/privacypolicy">Terms & Privacy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget footer-widget--time">
              <h3 className="footer-widget__title">Timing</h3>
              <p className="footer-widget__text">
                Mon Fri: 7:00am - 6:00pm <br /> Saturday: 9:00am - 5:00pm <br />{" "}
                Sunday: Closed
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget footer-widget--newsletter">
              <h3 className="footer-widget__title">Newsletter</h3>
              <form className="footer-widget__mailchimp">
                <input
                  type="email"
                  placeholder="customersupport@mybankshoppy.com"
                />
                <p className="footer-widget__mailchimp__text">
                  <i className="fa fa-check"></i>I agree to all your terms and
                  policies
                </p>
              </form>
            </div>
            <div className="col-lg-2 col-md-6" style={{ marginBottom: "5px" }}>
              <div>
                {/* <a href="#">
                    <img src="assets/icons/icon-apple.svg" alt="Apple" />
                  </a> */}

                <a href="#">
                  <img src="assets/icons/google-icon.svg" alt="Google" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-gutter-y-30 copyright-foot">
          <div className="col-lg-6 col-md-6">
            <p className="bottom-footer__text-company mb-0">
              Copyright &copy; 2024 MyBankShoppy. All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 col-md-6">
            <span className="bottom-footer__text-company mb-0">
              Designed and Developed by{" "}
              <a
                href="https://dremerz.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dremerz Consultancy and Services
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
