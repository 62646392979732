import React, { useState, useRef } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../Admin/Servicesadmin";

function Loan() {
  const [formData, setFormData] = useState({
    loanamount: "",
    income: "",
    purposeloan: "",
    loanyears: "",
    yourname: "",
    youremail: "",
    phonenumber: "",
    maritalstatus: "",
    birthdate: "",
    taxpayer: "",
    employer_status: "",
    office_location: "",
    pincode: "",
  });
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const formRef = useRef(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendFormData = async () => {
    try {
      await axios.post(
        "https://api.dremerz.net/api/mybankshoppylistofloanrequest",
        formData
      );

      await emailjs.send(
        "service_qkd2ctp",
        "template_9oxiyji",
        {
          loanamount: formData.loanamount,
          income: formData.income,
          purposeloan: formData.purposeloan,
          loanyears: formData.loanyears,
          yourname: formData.yourname,
          youremail: formData.youremail,
          phonenumber: formData.phonenumber,
          maritalstatus: formData.maritalstatus,
          birthdate: formData.birthdate,
          taxpayer: formData.taxpayer,
          employer_status: formData.employer_status,
          office_location: formData.office_location,
          pincode: formData.pincode,
        },
        "TICBQqLlGNj9B1wkg"
      );

      setFormData({
        loanamount: "",
        income: "",
        purposeloan: "",
        loanyears: "",
        yourname: "",
        youremail: "",
        phonenumber: "",
        maritalstatus: "",
        birthdate: "",
        taxpayer: "",
        employer_status: "",
        office_location: "",
        pincode: "",
      });
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await sendFormData();
    alert("Loan Application Submitted Successfully");
    setLoading(false);
  };

  const senddatafromcheckeligiblity = async (e) => {
    e.preventDefault();
    setLoading1(true);
    await sendFormData();
    navigate("/preeligibility");
    setLoading1(false);
  };

  return (
    <section className="finloan-apply-one pt-120 pb-120">
      <div className="container">
        {loading && (
          <div className="spinner-overlay">
            <div className="spinner"></div>
          </div>
        )}
        <form
          onSubmit={handleSubmit}
          ref={formRef}
          className="form-one contact-one__form"
        >
          <div className="contact-one__form-box">
            <div className="row">
              <div className="col-12">
                <div className="block-title">
                  <p className="block-title__tagline">
                    Calculate your loan amount
                  </p>
                  <h2 className="block-title__title">Loan Details</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Loan Amount*</label>
                  <input
                    className="form-control"
                    type="number"
                    name="loanamount"
                    placeholder="Loan Amount"
                    value={formData.loanamount}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Monthly Income/Annual Turnover*</label>
                  <input
                    className="form-control"
                    type="number"
                    name="income"
                    placeholder="Monthly Income"
                    value={formData.income}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="contact-one__form-box">
            <div className="row">
              <div className="col-12">
                <div className="block-title pt-50">
                  <p className="block-title__tagline">Ask for More Details</p>
                  <h2 className="block-title__title">Personal Details</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Full Name*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="yourname"
                    placeholder="Full Name"
                    value={formData.yourname}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Email*</label>
                  <input
                    className="form-control"
                    type="email"
                    name="youremail"
                    placeholder="Your Email"
                    value={formData.youremail}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Mobile Number*</label>
                  <input
                    className="form-control"
                    type="number"
                    name="phonenumber"
                    placeholder="Mobile Number"
                    value={formData.phonenumber}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Company Name*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="maritalstatus"
                    placeholder="Company Name"
                    value={formData.maritalstatus}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Monthly / Annual Turnover*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="birthdate"
                    placeholder="Income"
                    value={formData.birthdate}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Residence Location*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="taxpayer"
                    placeholder="Enter Residence Location"
                    value={formData.taxpayer}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Employment Status*</label>
                  <select
                    name="employer_status"
                    className="form-control"
                    value={formData.employer_status}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Employment Status</option>
                    <option value="Employed">Employed</option>
                    <option value="Self-Employed">Self-Employed</option>
                    <option value="Unemployed">Unemployed</option>
                    <option value="Student">Student</option>
                    <option value="Retired">Retired</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 col-12 mb-3">
                <div className="form-group">
                  <label>Office Location*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="office_location"
                    placeholder="Enter Office Location"
                    value={formData.office_location}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <div className="form-group">
              <label>Pincode*</label>
              <input
                className="form-control"
                type="text"
                name="pincode"
                placeholder="Enter Pincode"
                value={formData.pincode}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <button type="submit" className="thm-btn w-100">
                  <span className="thm-btn__text">
                    {loading ? <Spinner /> : "Apply Now"}
                  </span>
                </button>
              </div>

              <div className="col-12 col-md-auto">
                <button
                  type="button"
                  className="thm-btn w-100"
                  onClick={senddatafromcheckeligiblity}
                >
                  <span className="thm-btn__text">
                    {loading1 ? <Spinner /> : "Check Eligibility"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Loan;
