import { useEffect, useState } from "react";
import "./Forgetpassword.css";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { Spinner } from "../Admin/Servicesadmin";
import emailjs from "emailjs-com";

const Forgetpassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    getdata();

    try {
      await emailjs.send(
        "service_qkd2ctp",
        "template_2nxy9xq",
        {
          email: user.email,
          userid: user.userid,
          password: user.password,
          username: user.username,
        },
        "TICBQqLlGNj9B1wkg"
      );
      alert("Please Check Your Email Inbox For Login Credentials");
    } catch (error) {
      console.log("enter valid email", error);
    }
  };

  const getdata = async (e) => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyuserlistforleads?email=" + email
    );
    console.log(response.data, "response");
    setUser(response.data[0]);
  };

  useEffect(() => {
    console.log(user, "setuser");
  }, [user]);

  return (
    <div className="wrapper">
      <div className="logo">
        <img
          src="assets/images/favicons/mybankshoppylogotitle.png"
          alt="mybankshoppy logo"
        />
      </div>
      <div className="text-center mt-4 name" style={{ fontSize: "13px" }}>
        Enter Registered Email
      </div>
      <form className="p-3 mt-3" onSubmit={handleSubmit}>
        <div className="form-field d-flex align-items-center">
          <span className="far fa-envelope"></span>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="email"
            value={email}
            autoComplete="off"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <button className="btn mt-3" type="submit">
          {loading ? <Spinner /> : "Get Password Via Email"}
        </button>
      </form>
      <div className="text-center fs-6">
        <a href="login">Back To Login</a>
      </div>
    </div>
  );
};

export default Forgetpassword;
